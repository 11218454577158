import SaasHeader from 'pwasaasComponent/SaasHeader/SaasHeader.component';

/** @namespace Depassiewaard/Component/SaasHeader/Component/SaasHeaderComponent */
export class SaasHeaderComponent extends SaasHeader {
    renderSaasHeaderNavList() {
        return null;
    }
}

export default SaasHeaderComponent;
