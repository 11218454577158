import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { CheckoutFormType } from 'src/plugin/SaasLayout/type/SaasLayout';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/CheckoutAddressForm/CheckoutAddressForm.container';

import SaasCheckoutForm from './SaasCheckoutForm.component';
import {
    COMPANY,
    COUNTRY,
    EDIT_FIELDS,
    OPTIONAL,
    REQUIRED,
    VAT
} from './SaasCheckoutForm.config';

/** @namespace Pwasaas/Component/SaasCheckoutForm/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    checkoutFormFields: state.SaasLayoutReducer.checkout?.form || {},
    companyShow: state.SaasConfigReducer.config?.company_show || '',
    isDateOfBirthEnabled: state.SaasConfigReducer.config?.date_of_birth_enabled_in_checkout || false,
    isShowVatNumber: state.ConfigReducer.show_vat_number_on_storefront,
    vatNumberShow: state.ConfigReducer.show_tax_vat_number
});

/** @namespace Pwasaas/Component/SaasCheckoutForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Pwasaas/Component/SaasCheckoutForm/Container/SaasCheckoutFormContainer */
export class SaasCheckoutFormContainer extends PureComponent {
    static propTypes = {
        checkoutFormFields: CheckoutFormType.isRequired,
        companyShow: PropTypes.string.isRequired,
        isDateOfBirthEnabled: PropTypes.bool.isRequired,
        isShowVatNumber: PropTypes.bool.isRequired,
        showNotification: PropTypes.func.isRequired,
        vatNumberShow: PropTypes.string.isRequired
    };

    customFieldsOptionMap = {
        [COMPANY]: {
            conditions: [
                {
                    handler: (prop) => [OPTIONAL, REQUIRED].includes(prop),
                    prop: '*',
                    selector: 'props.companyShow'
                },
                {
                    handler: (prop) => prop === REQUIRED,
                    prop: 'validation',
                    selector: 'props.companyShow'
                }
            ]
        },
        [COUNTRY]: {
            conditions: [
                {
                    handler: (item) => item.length === 1,
                    prop: 'type',
                    selector: 'selectOptions'
                }
            ]
        },
        [VAT]: {
            conditions: [
                {
                    handler: (prop) => [OPTIONAL, REQUIRED].includes(prop),
                    prop: '*',
                    selector: 'props.vatNumberShow'
                },
                {
                    handler: (prop) => prop === REQUIRED,
                    prop: 'validation',
                    selector: 'props.vatNumberShow'
                },
                {
                    handler: (prop) => prop === false,
                    prop: 'type',
                    selector: 'props.isShowVatNumber'
                }
            ]
        }
    };

    state = {
        editFields: EDIT_FIELDS
    };

    containerFunctions = {};

    containerProps = () => {
        const {
            customFieldsOptionMap,
            props: {
                checkoutFormFields,
                companyShow,
                isDateOfBirthEnabled,
                isShowVatNumber,
                vatNumberShow
            },
            state: {
                editFields
            }
        } = this;

        return {
            /** add CheckoutAddressForm mapped source props */
            ...this.props,
            checkoutFormFields,
            companyShow,
            customFieldsOptionMap,
            editFields,
            isDateOfBirthEnabled,
            isShowVatNumber,
            vatNumberShow
        };
    };

    render() {
        return (
            <SaasCheckoutForm
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaasCheckoutFormContainer);
