import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import OrderQuery from 'Query/Order.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { orderType } from 'Type/Account';
import history from 'Util/History';
import { getIndexedProducts } from 'Util/Product';
import { fetchQuery } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

import MyAccountReorderButton from './MyAccountReorderButton.component';
import { CART_URL } from './MyAccountReorderButton.config';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Pwasaas/Component/MyAccountReorderButton/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Pwasaas/Component/MyAccountReorderButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addProductToCart: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Pwasaas/Component/MyAccountReorderButton/Container/MyAccountReorderButtonContainer */
export class MyAccountReorderButtonContainer extends PureComponent {
    static propTypes = {
        addProductToCart: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        order: orderType,
        orderId: PropTypes.number,
        showNotification: PropTypes.func.isRequired,
        title: PropTypes.string
    };

    static defaultProps = {
        order: {},
        orderId: null,
        title: __('Reorder')
    };

    state = {
        isLoading: false
    };

    containerFunctions = {
        handleClick: this.handleClick.bind(this)
    };

    containerProps = () => {
        const { title } = this.props;
        const { isLoading } = this.state;

        return {
            isLoading,
            title
        };
    };

    _addProductsToCart(products) {
        const {
            addProductToCart,
            hideActiveOverlay,
            showNotification
        } = this.props;

        if (!products.length) {
            return;
        }

        Promise.all(
            products.map((product) => {
                const { qty: quantity } = product;
                const options = {
                    product,
                    quantity
                };

                return addProductToCart(options);
            })
        ).then(
            /** @namespace Pwasaas/Component/MyAccountReorderButton/Container/all/then */
            () => {
                hideActiveOverlay();
                history.push({ pathname: appendWithStoreCode(CART_URL) });

                showNotification('success', __('Products added to cart!'));
            },
            /** @namespace Pwasaas/Component/MyAccountReorderButton/Container/all/then */
            () => showNotification('error', __('Something went wrong while adding products to cart!'))
        ).finally(
            this.setState({ isLoading: false })
        );
    }

    _fetchOrderAndAddProducts() {
        const { orderId } = this.props;

        fetchQuery(OrderQuery.getOrderByIdQuery(orderId)).then(
            /** @namespace Pwasaas/Component/MyAccountReorderButton/Container/fetchQuery/then */
            ({ getOrderById: rawOrder }) => {
                const { order_products = [] } = rawOrder;
                const indexedProducts = getIndexedProducts(order_products);

                if (!indexedProducts.length) {
                    showNotification('error', __('Something went wrong while fetching order!'));
                    this.setState({ isLoading: false });

                    return;
                }

                this._addProductsToCart(indexedProducts);
            },
            /** @namespace Pwasaas/Component/MyAccountReorderButton/Container/fetchQuery/then */
            () => {
                showNotification('error', __('Error getting Order by ID!'));
                this.setState({ isLoading: false });
            }
        );
    }

    handleClick(e) {
        e.stopPropagation();
        this.setState({ isLoading: true });

        const {
            order,
            orderId
        } = this.props;

        if (!orderId && Object.keys(order).length) {
            const { order_products = [] } = order;

            this._addProductsToCart(order_products);

            return;
        }

        this._fetchOrderAndAddProducts();
    }

    render() {
        return (
            <MyAccountReorderButton
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountReorderButtonContainer);
