import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import GiftCardQuery from 'Query/GiftCard.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { MixType } from 'Type/Common';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';

import GiftCard from './GiftCard.component';
import { DEFAULT_TITLE } from './GiftCard.config';

/** @namespace Pwasaas/Component/GiftCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    mediaBaseUrl: state.ConfigReducer.secure_base_media_url
});

/** @namespace Pwasaas/Component/GiftCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Pwasaas/Component/GiftCard/Container/GiftCardContainer */
export class GiftCardContainer extends PureComponent {
    static propTypes = {
        mediaBaseUrl: PropTypes.string.isRequired,
        mix: MixType,
        title: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({})
        ])
    };

    static defaultProps = {
        mix: {},
        title: DEFAULT_TITLE
    };

    state = {
        isImageVisible: false,
        isFormVisible: true
    };

    containerFunctions = {
        handleSubmit: this.onSubmit.bind(this),
        handleToggleImage: this.onToggleImage.bind(this)
    };

    containerProps = () => {
        const {
            mediaBaseUrl,
            mix,
            title
        } = this.props;

        const { isImageVisible, isFormVisible } = this.state;

        return {
            mediaBaseUrl,
            mix,
            title,
            isImageVisible,
            isFormVisible
        };
    };

    onSubmit(inputValue) {
        return fetchMutation(GiftCardQuery.getUpdateGiftMessageMutation(
            inputValue,
            !isSignedIn() && getGuestQuoteId()
        )).then(
            /** @namespace Pwasaas/Component/GiftCard/Container/fetchMutation/then */
            () => showNotification('success', 'Gift card saved!'),
            /** @namespace Pwasaas/Component/GiftCard/Container/fetchMutation/then */
            (error) => showNotification('error', 'Error saving gift message', error)
        );
    }

    onToggleImage() {
        this.setState(({
            isImageVisible: wasImageVisible,
            isFormVisible: wasFormVisible
        }) => ({
            isImageVisible: !wasImageVisible,
            isFormVisible: !wasFormVisible
        }));
    }

    render() {
        return (
            <GiftCard
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardContainer);
