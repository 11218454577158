/** @namespace Pwasaas/Util/Saas/Form/isValidByKey */
export const isValidByKey = (key, {
    conditions,
    field,
    instance
}) => {
    const {
        handler,
        selector
    } = conditions.find(({ prop }) => prop === key) || {};

    if (!handler || !selector) {
        return true;
    }

    const [target, propKey] = selector.split('.');
    const item = !propKey ? field[target] : instance[target][propKey];

    return handler(item);
};

/** @namespace Pwasaas/Util/Saas/Form/getSelectedFormProps */
export const getSelectedFormProps = (
    conditions = [],
    newField,
    editField,
    instance
) => {
    const field = { ...newField, ...editField };

    if (!conditions.length) {
        return field;
    }

    const options = {
        conditions,
        field,
        instance
    };

    if (!field.enabled || !isValidByKey('*', options)) {
        return { enabled: false };
    }

    return Object.entries(field).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key]: isValidByKey(key, options)
                ? editField[key] || value
                : newField[key]
        }), {}
    );
};
